import styles from './LaboratoryPage.module.css';

export const LaboratoryPage = () => {
  return (
    <>
      <div className={styles.promo}>
        <div className={styles.promoDescr}>
          <span>ЛАБОРАТОРИЯ АНАЛИЗА ИЗДЕЛИЙ </span> <br />
          <p>
           Лаборатория производит механические испытания, химические, спектральные анализы металлов и материалов, а также металлографические исследования. Сотрудники лаборатории имеют сертификаты компетентности специалистов по неразрушающему и разрушающему контролю.
          </p>
        </div>
        <div className={styles.promoRectangle}></div>
      </div>
      <div className={styles.promoPossibilities}>
        Отдел Главного сварщика Государственного предприятия «ОКБ Академическое» аккредитован ГП «БГЦА» на соответствие СТБ ИСО/МЭК 17025, 
        аттестат аккредитации № BY/112 02.2.0.0641 до 19.04.2025 г.
        <br />
        <br />
        Отдел главного сварщика (отдел 92) Государственного предприятия «ОКБ Академическое» выполняет следующие виды работ:
        <ul>
          <li>Физико-механические испытания металлов;</li>
          <li>Спектральный анализ металлов;</li>
          <li>Измерение твердости основного металла и сварных соединений;</li>
          <li>Внешний осмотр и измерения, визуально-оптический метод;</li>
          <li>Рентгенографическая дефектоскопия;</li>
          <li>Ультразвуковая дефектоскопия; ультразвуковая толщинометрия;</li>
          <li>Цветная дефектоскопия;</li>
          <li>Гидравлические испытания на прочность, плотность и герметичность (пробное давление до 40Мпа) на соответствие 
            требованиям ТР ТС 032/2013 "О безопасности оборудования, работающего под избыточным давлением" и другим ТНПА.
          </li>
        </ul>
        <br />
        Лицензия на осуществление деятельности в области использования атомной энергии и источников ионизирующего излучения № 15200000006012, 
        действующая c 14.04.2014 г., дата принятия решения о изменении: 28.06.2023.
      </div>
    </>
  )
};
